import React from 'react'

import {
  Dialog,
  Button,
  DialogProps,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  makeStyles,
  Checkbox,
  Theme,
  useScrollTrigger,
  Zoom,
  Fab,
  Paper,
  Menu,
  MenuItem,
  TextField,
  Slide,
  ClickAwayListener,
  ThemeProvider,
  createTheme,
  InputBase,
  alpha,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box
} from '@material-ui/core'

import {
  ArrowBackRounded,
  CloseRounded,
  KeyboardArrowUpRounded,
  MoreVertRounded,
  PublishRounded,
  SearchRounded
} from '@material-ui/icons'

import Gallery, { RenderImageProps } from 'react-photo-gallery'

import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'

import { ReactComponent as Binary } from './svg/binary.svg'
import { ReactComponent as Video } from './svg/video.svg'
import { ReactComponent as Document } from './svg/document.svg'
import { ReactComponent as PDF } from './svg/pdf.svg'

import LoadingAnimation from 'loadingAnimation/uploadAnimation.gif'

export interface FileSelectorPropsLinks {
  method: HTTPMethod
  url: Link
  settings?: Omit<RequestInit, 'method'>
}

export type Link = string
export type HTTPMethod = string

export interface FileResponse {
  path: Link
  displayName: string
  filetype: 'image' | 'document' | 'pdf' | 'binary' | 'video' | 'vectorgraphic'
  width?: number
  height?: number
}

export interface ExtendedFileResponse extends Omit<FileResponse, 'filetype'> {
  filetype: FileResponse['filetype'] | 'upload'
}

const fileSelector = makeStyles((theme: Theme) => ({
  fileSelector: {
    flexGrow: 1
  },
  closeButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    marginRight: theme.spacing(2)
  },
  fileSelectorDialog: {
    borderRadius: `0 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    // Mobile
    height: '100%',
    margin: 0,
    //Desktop
    [theme.breakpoints.up('md')]: {
      height: `calc(100% - ${theme.spacing(5) * 2}px)`,
      margin: theme.spacing(5)
    }
  },
  scrollTop: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    },
    marginRight: 'auto'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch'
      }
    }
  },
  saveButton: {
    flexShrink: 0
  }
}))

export interface FileSelectorProps {
  links: {
    get: FileSelectorPropsLinks
    upload?: FileSelectorPropsLinks
    delete?: FileSelectorPropsLinks
    rename?: FileSelectorPropsLinks
    root?: Link
  }
  callback?: (files: FileResponse[]) => void
  callbackDone?: (files: FileResponse[]) => void
  filter?: (files: FileResponse[]) => FileResponse[]
  previewImage?: (path: string) => string
  pickedFiles?: Link[]
  multiple?: boolean
  size?: number
  useSnackbar?: {
    enqueueSnackbar: (
      message: SnackbarMessage,
      options?: OptionsObject | undefined
    ) => SnackbarKey
    closeSnackbar: (key?: SnackbarKey | undefined) => void
  }
}

export default function FileSelector(props: FileSelectorProps & DialogProps) {
  const classes = fileSelector()

  const {
    links,
    callback,
    callbackDone,
    filter,
    previewImage,
    pickedFiles,
    multiple,
    size,
    useSnackbar,

    className,
    ...dialogProps
  } = props

  const [files, setFiles] = React.useState([] as unknown as FileResponse[])
  const [loading, setLoading] = React.useState(true)
  const [selectedFiles, setSelectedFiles] = React.useState(
    [] as unknown as FileResponse[]
  )

  const [search, setSearch] = React.useState('')

  const fileSelectorRef = React.useRef() as React.RefObject<HTMLElement>

  return (
    <Dialog
      {...dialogProps}
      className={[className, classes.fileSelector].join(' ')}
      PaperProps={{
        className: classes.fileSelectorDialog,
        ref: (node: HTMLElement | null) => node && fileSelectorRef
      }}
      fullScreen
    >
      <AppBar position='sticky'>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            className={classes.closeButton}
            onClick={() =>
              props.callbackDone?.(
                files.filter((file: FileResponse) =>
                  props.pickedFiles?.includes(file.path)
                )
              )
            }
            aria-label='close'
          >
            <CloseRounded />
          </IconButton>

          <Typography variant='h6' className={classes.title}>
            Dateiauswahl
          </Typography>
          <Box className={classes.search}>
            <Box className={classes.searchIcon}>
              <SearchRounded />
            </Box>
            <InputBase
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              placeholder='Suche...'
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Box>

          <Button
            autoFocus
            color='inherit'
            className={classes.saveButton}
            onClick={() => props.callbackDone?.(selectedFiles)}
            key={JSON.stringify(selectedFiles)}
          >
            Speichern
          </Button>
        </Toolbar>
      </AppBar>

      <FileSelectorRaw
        {...{
          links: { root: '', ...links },
          callback,
          callbackDone,
          previewImage,
          pickedFiles,
          multiple,
          size,
          useSnackbar,
          files,
          loading,
          selectedFiles,
          setFiles,
          setLoading,
          setSelectedFiles
        }}
        filter={(files) => {
          const filteredFiles = filter !== undefined ? filter(files) : files
          const searchedFiles = filteredFiles.filter((files) =>
            files.displayName.toLowerCase().includes(search.toLowerCase())
          )
          return searchedFiles.length ? searchedFiles : filteredFiles
        }}
      />

      <Zoom
        in={
          fileSelectorRef.current
            ? useScrollTrigger({
                target: fileSelectorRef.current,
                disableHysteresis: true,
                threshold: 100
              })
            : undefined
        }
      >
        <Box
          onClick={() =>
            fileSelectorRef.current?.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }
          className={classes.scrollTop}
          role='presentation'
        >
          <Fab color='primary' size='small' aria-label='scroll back to top'>
            <KeyboardArrowUpRounded />
          </Fab>
        </Box>
      </Zoom>
    </Dialog>
  )
}

const fileSelectorRaw = makeStyles(() => ({
  fileSelectorRawWrapper: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  fileSelectorRaw: {
    overflow: 'auto',
    width: '100%'
  },
  loadingAnimation: {
    alignSelf: 'center'
  }
}))

const FileSelectorData = React.createContext<FileSelectorDataProps>(undefined!)

export interface FileSelectorRawProps extends FileSelectorProps {
  files: FileResponse[]
  loading: boolean
  selectedFiles: FileResponse[]
  setFiles: React.Dispatch<React.SetStateAction<FileResponse[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedFiles: React.Dispatch<React.SetStateAction<FileResponse[]>>
}

interface FileSelectorDataProps {
  rootPath?: Link
  previewImage?: (path: string) => string
  multiple: boolean
  uploading: boolean
  setSelected: React.Dispatch<React.SetStateAction<FileResponse[]>>
  onUpload: (files: File[]) => void
  onRename: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    file: FileResponse
  ) => void
  onRemove: (file: FileResponse) => void
  onMaximize: (file: FileResponse) => void
  removable: boolean
  renamable: boolean
}

FileSelectorRaw.defaultProps = {
  pickedFiles: [],
  multiple: false
}

export function FileSelectorRaw(props: FileSelectorRawProps) {
  const classes = fileSelectorRaw()

  // Abort fetch requests on unmount
  const abortController = React.useRef(new AbortController())

  const [removePrompt, setRemovePrompt] = React.useState(false)
  const [removeFile, setRemoveFile] = React.useState(
    undefined as FileResponse | undefined
  )

  const [expandedFile, setExpandedFile] = React.useState({
    open: false,
    file: undefined as undefined | FileResponse
  })

  const [dropHover, setDropHover] = React.useState(false)

  const [uploadCount, setUploadCount] = React.useState(0 as number)

  const initialSelectedFiles = React.useRef(props.pickedFiles)

  const fetchData = {
    defaultData: {
      credentials: 'include' as RequestCredentials,
      signal: abortController.current.signal
    },
    error: {
      timeout:
        'Der Server reagiert nicht. Überprüfen Sie ihre Internetverbindung und versuchen Sie es in kurzer Zeit erneut.',
      upload: `Die Datei*en konnte nicht hochgeladen werden. Versuchen Sie es in kurzer Zeit erneut.`,
      delete: `Die Datei "§name" mit dem Pfad "§path" konnte nicht gelöscht werden. Versuchen Sie es in kurzer Zeit erneut.`,
      rename: `Die Datei "§name" mit dem Pfad "§path" konnte nicht umbenannt werden. Versuchen Sie es in kurzer Zeit erneut.`
    },
    success: {
      upload: 'Daten erfolgreich hochgeladen',
      delete: 'Daten erfolgreich gelöscht',
      rename: 'Datei erfolgreich umbenannt'
    },
    dropzone: 'Drag-And-Drop Dateiupload'
  }

  React.useEffect(
    () =>
      props.setSelectedFiles(() => {
        // Apply selected files by path when pickedFiles changes
        const selectedFiles = props.files.filter((file: FileResponse) =>
          props.pickedFiles?.some((pickedFile) =>
            pickedFile.includes(file.path)
          )
        )

        return selectedFiles.slice(0, props.multiple ? undefined : 1)
      }),
    [props.pickedFiles]
  )

  React.useEffect(
    () => props.callback?.(props.selectedFiles),
    [props.selectedFiles]
  )

  React.useEffect(() => {
    fileFetch()
      // First file filtering for picked files
      .then((files) => {
        if (files)
          props.setSelectedFiles(
            files
              .filter((file: FileResponse) =>
                props.pickedFiles?.some((pickedFile) =>
                  pickedFile.includes(file.path)
                )
              )
              .slice(0, props.multiple ? undefined : 1)
          )
      })
    const fetchInterval = setInterval(() => fileFetch(), 60 * 1000)

    return () => {
      // Abort and cancel all current fetches
      abortController.current.abort()
      clearInterval(fetchInterval)
    }
  }, [])

  const fileFetch = async () => {
    const files: FileResponse[] | undefined = await fetch(props.links.get.url, {
      method: props.links.get.method,
      ...fetchData.defaultData,
      ...props.links.get.settings
    })
      .then((response) => {
        props.setLoading(false)
        return response.json()
      })
      .catch(
        // Do not trigger an error on unmount
        () => {
          if (!abortController.current.signal.aborted)
            error(fetchData.error.timeout)
        }
      )

    if (files) {
      props.setFiles(files.reverse())

      props.setSelectedFiles((selectedFiles) =>
        // Apply selected files by path
        files
          .filter((file: FileResponse) =>
            selectedFiles.some((selected) => selected.path.includes(file.path))
          )
          .slice(0, props.multiple ? undefined : 1)
      )
    }
    return files
  }

  const error = (text: string) =>
    props.useSnackbar?.enqueueSnackbar(text, { variant: 'error' })

  const onUpload = (files: File[]) => {
    let formData = new FormData()

    for (var index = 0; index < files.length; index++) {
      formData.append(files[index].name, files[index])
    }

    // Kepp track of uploaded file requests
    setUploadCount((count) => count + 1)

    fetch(props.links.upload?.url || '', {
      method: props.links.upload?.method,
      ...fetchData.defaultData,
      body: formData,
      ...props.links.upload?.settings
    })
      .then((response) => {
        if (response.status !== 200) error(fetchData.error.upload)
        else {
          response
            .json()
            .then((allFiles: FileResponse[]) =>
              props.setFiles(allFiles.reverse())
            )
          props.useSnackbar?.enqueueSnackbar(fetchData.success.upload, {
            variant: 'success'
          })
        }
      })
      .then(() => setUploadCount((count) => count - 1))
      .catch(() => {
        setUploadCount((count) => count - 1)
        error(fetchData.error.timeout)
      })
  }

  const onRename = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    file: FileResponse
  ) => {
    event.persist()

    if (event.target.value !== file.displayName)
      fetch(props.links.rename?.url || '', {
        method: props.links.rename?.method,
        ...fetchData.defaultData,
        body: JSON.stringify({
          path: file.path,
          newDisplayName: event.target.value
        }),
        ...props.links.rename?.settings,
        headers: {
          'Content-Type': 'application/json',
          ...props.links.rename?.settings?.headers
        }
      })
        .then((response) => {
          if (response.status !== 200) {
            event.target.value = file.displayName
            error(
              fetchData.error.rename
                .replace('§name', file.displayName)
                .replace('§path', file.path)
            )
          } else {
            props.useSnackbar?.enqueueSnackbar(fetchData.success.rename, {
              variant: 'success'
            })
            // Update files with new displayName
            props.setFiles((files) =>
              files.map((oldFile) =>
                file.path === oldFile.path
                  ? { ...file, displayName: event.target.value }
                  : oldFile
              )
            )
          }
        })
        .catch(() => error(fetchData.error.timeout))
  }

  const onRemove = (file: FileResponse) => {
    setRemoveFile(file)
    setRemovePrompt(true)
  }

  const abortRemove = () => setRemovePrompt(false)

  const confirmRemove = () =>
    fetch(props.links.delete?.url || '', {
      method: props.links.delete?.method,
      ...fetchData.defaultData,
      body: JSON.stringify({
        path: (removeFile as FileResponse).path
      }),
      ...props.links.delete?.settings,
      headers: {
        'Content-Type': 'application/json',
        ...props.links.delete?.settings?.headers
      }
    })
      .then((response) => {
        if (response.status !== 200)
          error(
            fetchData.error.rename
              .replace('§name', (removeFile as FileResponse).displayName)
              .replace('§path', (removeFile as FileResponse).path)
          )
        else {
          props.setFiles((files) =>
            files.filter(
              (filterFile) =>
                filterFile.path !== (removeFile as FileResponse).path
            )
          )
          props.useSnackbar?.enqueueSnackbar(fetchData.success.delete, {
            variant: 'success'
          })
        }
      })
      .catch(() => error(fetchData.error.timeout))
      .finally(() => setRemovePrompt(false))

  const onMaximize = (file: FileResponse) =>
    setExpandedFile({ open: true, file })

  // Filter and sort displayed files
  const displayedFiles = (props.filter?.(props.files) || props.files).sort(
    (fileA, fileB) =>
      (initialSelectedFiles.current?.some((path) => path.includes(fileB.path))
        ? 1
        : 0) -
      (initialSelectedFiles.current?.some((path) => path.includes(fileA.path))
        ? 1
        : 0)
  )

  return (
    <FileSelectorData.Provider
      value={{
        rootPath: props.links.root,
        previewImage: props.previewImage,
        multiple: props.multiple || false,
        uploading: !!uploadCount,
        setSelected: props.setSelectedFiles,
        onUpload,
        onRename,
        onRemove,
        onMaximize,
        removable: props.links.delete !== undefined,
        renamable: props.links.rename !== undefined
      }}
    >
      <FileSelectorExpandedFile
        open={expandedFile.open}
        currentFile={expandedFile.file as ExpandedFileFileResponse}
        onClose={() => setExpandedFile({ ...expandedFile, open: false })}
      />

      <Dialog open={removePrompt} onClose={abortRemove}>
        <DialogTitle>Achtung</DialogTitle>
        <DialogContent>Möchten Sie die Datei wirklich löschen?</DialogContent>
        <DialogActions>
          <Button onClick={abortRemove}>Abbrechen</Button>
          <Button color='primary' onClick={confirmRemove}>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>

      <Box className={classes.fileSelectorRawWrapper}>
        <FileSelectorDrop
          open={dropHover}
          onClose={() => setDropHover(false)}
        />

        {props.files.length || !props.loading ? (
          <Box
            className={classes.fileSelectorRaw}
            style={{ pointerEvents: dropHover ? 'none' : 'all' }}
            onDragOver={() => setDropHover(true)}
          >
            <Gallery
              photos={(props.links.upload !== undefined
                ? [
                    {
                      path: '',
                      displayName: '',
                      filetype: 'upload'
                    } as ExtendedFileResponse,
                    ...displayedFiles
                  ]
                : displayedFiles
              ).map((file) => ({
                src: file.path,
                alt: JSON.stringify({
                  displayName: file.displayName,
                  filetype: file.filetype
                }),
                width: file.width || 1,
                height: file.height || 1
              }))}
              renderImage={(imageProps) => (
                <FileSelectorTile
                  {...{
                    ...imageProps,
                    selected: props.selectedFiles.some((selected) =>
                      selected.path.includes(imageProps.photo.src)
                    )
                  }}
                />
              )}
              targetRowHeight={props.size}
            />
          </Box>
        ) : (
          <CircularProgress className={classes.loadingAnimation} />
        )}
      </Box>
    </FileSelectorData.Provider>
  )
}

const fileSelectorExpandedFile = makeStyles(() => ({
  expandedFile: {
    height: '100%',
    background: 'transparent',
    borderRadius: 0
  },
  file: {
    height: '100%',
    objectFit: 'contain'
  },
  left: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    left: 0
  },
  right: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    right: 0
  }
}))

interface FileSelectorExpandedFileProps {
  open: boolean
  currentFile?: ExpandedFileFileResponse
  onClose: () => void
}

interface ExpandedFileFileResponse extends Omit<FileResponse, 'filetype'> {
  filetype: Exclude<FileResponse['filetype'], 'binary' | 'video' | 'document'>
}

const FileSelectorExpandedFile = (props: FileSelectorExpandedFileProps) => {
  const classes = fileSelectorExpandedFile()

  const fileSelectorData = React.useContext(FileSelectorData)

  const [currentName, setCurrentName] = React.useState(
    props.currentFile?.displayName
  )
  const [menuOpen, setMenuOpen] = React.useState(false)

  const menuRef = React.useRef() as React.RefObject<HTMLButtonElement>

  React.useEffect(
    () => setCurrentName(props.currentFile?.displayName),
    [props.currentFile?.displayName]
  )

  const handleClose = () => setMenuOpen(false)

  const handleOpen = () => setMenuOpen(true)

  const displayedImage = (file: ExpandedFileFileResponse) => {
    switch (file.filetype) {
      case 'image':
      case 'vectorgraphic':
        return (
          <img
            src={fileSelectorData.rootPath + file.path}
            className={classes.file}
            onClick={props.onClose}
          />
        )
      case 'pdf':
        return (
          <iframe
            src={fileSelectorData.rootPath + file.path}
            className={classes.file}
          />
        )
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{ className: classes.expandedFile }}
      BackdropProps={{ style: { backgroundColor: '#000000b8' } }}
      maxWidth={false}
      fullWidth={props.currentFile?.filetype === 'pdf'}
    >
      {props.currentFile ? displayedImage(props.currentFile) : null}

      <ThemeProvider
        theme={(theme: Theme) => {
          // Omit "light"-mode properties
          const { type, text, common, action, ...rest } = theme.palette
          return createTheme({
            ...theme,
            palette: { ...rest, type: 'dark' }
          })
        }}
      >
        <Box className={classes.left}>
          <IconButton onClick={props.onClose}>
            <ArrowBackRounded />
          </IconButton>
          {fileSelectorData.renamable && (
            <TextField
              value={currentName}
              onChange={(event) => setCurrentName(event.currentTarget.value)}
              onBlur={(event) =>
                props.currentFile &&
                fileSelectorData.onRename(
                  event,
                  props.currentFile as FileResponse
                )
              }
            />
          )}
        </Box>

        <Box className={classes.right}>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            onClick={handleOpen}
            ref={menuRef}
          >
            <MoreVertRounded />
          </IconButton>
        </Box>
      </ThemeProvider>

      <Menu
        open={menuOpen}
        onClose={handleClose}
        anchorEl={menuRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        {fileSelectorData.removable && (
          <MenuItem
            onClick={() => {
              fileSelectorData.onRemove(props.currentFile as FileResponse)
              handleClose()
              props.onClose()
            }}
          >
            Löschen
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>
          <DownloadButton
            path={`${fileSelectorData.rootPath}${props.currentFile?.path}`}
            name={props.currentFile?.displayName}
          />
        </MenuItem>
      </Menu>
    </Dialog>
  )
}

const fileSelectorDrop = makeStyles((theme: Theme) => ({
  dropZonePositioner: (props: { dropHover: boolean }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer,
    pointerEvents: !props.dropHover ? 'none' : 'all'
  }),
  dropZoneWrapper: {
    height: `calc(100% - ${(12 + 3) * 2}px)`,
    border: 3,
    borderStyle: 'dashed',
    background: theme.palette.action.disabledBackground,
    margin: 12,
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    width: 300,
    height: 300,
    fill: theme.palette.common.black,
    stroke: theme.palette.common.white,
    strokeWidth: 0.2
  }
}))

interface FileSelectorDropProps {
  open: boolean
  onClose: () => void
}

const FileSelectorDrop = (props: FileSelectorDropProps) => {
  const classes = fileSelectorDrop({ dropHover: props.open })

  const fileSelectorData = React.useContext(FileSelectorData)

  return (
    <Box
      className={classes.dropZonePositioner}
      onDragOver={(event) => {
        event.stopPropagation()
        event.preventDefault()
        event.dataTransfer.dropEffect = 'copy'
      }}
      onDragLeave={(event) => {
        event.stopPropagation()
        event.preventDefault()
        props.onClose()
      }}
      onDrop={(event) => {
        event.stopPropagation()
        event.preventDefault()

        let files = [] as File[]

        // Check if dropped data is a file
        for (let i = 0; i < event.dataTransfer.items.length; i++)
          if (event.dataTransfer.items[i].kind === 'file')
            files.push(event.dataTransfer.items[i].getAsFile() as File)

        if (files.length) fileSelectorData.onUpload(files)

        props.onClose()
      }}
    >
      <Zoom in={props.open} timeout={{ enter: 100, exit: 250 }}>
        <Paper elevation={4} className={classes.dropZoneWrapper}>
          <PublishRounded className={classes.icon} />
        </Paper>
      </Zoom>
    </Box>
  )
}

const FileSelectorTile = (
  props: Omit<FileSelectorFileProps, 'photoData'> & RenderImageProps
) => {
  const photoData = {
    ...JSON.parse(props.photo.alt as string),
    path: props.photo.src
  } as ExtendedFileResponse

  return photoData.filetype === 'upload' ? (
    <FileSelectorUpload {...props} />
  ) : (
    <FileSelectorFile {...props} photoData={photoData as FileResponse} />
  )
}

const fileSelectorFile = makeStyles((theme: Theme) => ({
  fileSelectorFile: {
    overflow: 'hidden',
    position: 'relative',
    left: 0,
    top: 0,
    padding: 0,
    cursor: 'context-menu'
  },
  fileRoot: {
    width: '100%',
    height: '100%',
    minWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    padding: 0
  },
  fileLabel: {
    width: '100%',
    height: '100%'
  },
  file: {
    transition: theme.transitions.create(['transform']),
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    overflow: 'hidden'
  },
  pdfFile: {
    width: `calc(100% + ${16 + 6 + 2}px)`,
    height: `calc(100% + ${6}px)`,
    marginTop: -6,
    marginLeft: -6,
    position: 'absolute',
    left: 0,
    top: 0,
    objectFit: 'cover',
    pointerEvents: 'none'
  },
  buttonWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    borderRadius: 4,
    pointerEvents: 'none',
    '& *': {
      pointerEvents: 'all'
    }
  },
  buttonCheckbox: {
    position: 'absolute',
    transition: theme.transitions.create(['opacity']),
    zIndex: theme.zIndex.appBar,
    top: 0,
    left: 0
  },
  buttonMenu: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
    top: 0,
    right: 0
  },
  menuIcon: {
    stroke: theme.palette.common.white
  },
  buttonInput: {
    position: 'absolute',
    zIndex: theme.zIndex.appBar,
    bottom: 0,
    right: 0,
    padding: 7,
    width: `calc(100% - ${7 * 2}px)`,
    background: alpha(theme.palette.background.paper, 0.6)
  }
}))

interface FileSelectorFileProps {
  selected: boolean
  photoData: FileResponse
}

const initialMousePositionState = {
  mouseX: 0,
  mouseY: 0,
  open: false
}

const FileSelectorFile = (props: FileSelectorFileProps & RenderImageProps) => {
  const classes = fileSelectorFile()

  const fileSelectorData = React.useContext(FileSelectorData)

  const menuRef = React.useRef() as React.RefObject<HTMLButtonElement>

  const [menu, setMenu] = React.useState<{
    mouseX: number
    mouseY: number
    open: boolean
  }>(initialMousePositionState)

  const [editOpen, setEditOpen] = React.useState(false)
  const [currentName, setCurrentName] = React.useState(
    props.photoData.displayName
  )

  React.useEffect(
    () => setCurrentName(props.photoData.displayName),
    [props.photoData.displayName]
  )

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault()
    // Close current menu
    if (menu.open) handleClose()
    else
      setMenu({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        open: true
      })
  }

  const handleMenuClick = () => {
    setMenu({
      ...menu,
      open: true
    })
  }

  const handleClose = () => {
    setMenu(initialMousePositionState)
  }

  const displayedImage = (file: FileResponse) => {
    switch (file.filetype) {
      case 'image':
      case 'vectorgraphic':
        return (
          <img
            src={
              fileSelectorData.rootPath +
              (fileSelectorData.previewImage?.(file.path) || file.path)
            }
            className={classes.file}
            style={{
              transform: `scale(${props.selected ? 0.9 : 1})`
            }}
            loading='lazy'
          />
        )
      case 'document':
        return <Document className={classes.file} />
      case 'pdf':
        return navigator.userAgent.includes('Chrome') ? (
          <Box
            className={classes.file}
            style={{
              transform: `scale(${props.selected ? 0.9 : 1})`
            }}
          >
            <iframe
              className={classes.pdfFile}
              src={
                fileSelectorData.rootPath +
                (fileSelectorData.previewImage?.(file.path) || file.path) +
                '#scrollbar=0&toolbar=0&navpanes=0&view=FitH&zoom=FitH'
              }
              loading='lazy'
              width='100%'
              height='100%'
            />
          </Box>
        ) : (
          <PDF className={classes.file} />
        )
      case 'video':
        return <Video className={classes.file} />
      default:
        return <Binary className={classes.file} />
    }
  }

  return (
    <Box
      className={classes.fileSelectorFile}
      style={
        {
          margin: props.margin,
          height: props.photo.height,
          width: props.photo.width,
          ...(props.direction === 'column' && {
            position: 'absolute',
            left: props.left as number,
            top: props.top as number
          })
        } as React.CSSProperties
      }
      onContextMenu={handleContextMenu}
    >
      <Button
        variant='outlined'
        color='default'
        onClick={() =>
          fileSelectorData.setSelected((selected) =>
            fileSelectorData.multiple
              ? props.selected
                ? selected.filter((file) => file.path !== props.photoData.path)
                : selected.concat([props.photoData as FileResponse])
              : props.selected
              ? []
              : [props.photoData as FileResponse]
          )
        }
        classes={{ label: classes.fileLabel, root: classes.fileRoot }}
        key={props.index}
      >
        {displayedImage(props.photoData)}
      </Button>
      <Box className={classes.buttonWrapper}>
        <Checkbox
          checked={true}
          style={{ opacity: props.selected ? 1 : 0 }}
          className={classes.buttonCheckbox}
          color='primary'
        />
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          className={classes.buttonMenu}
          onClick={handleMenuClick}
          ref={menuRef}
        >
          <MoreVertRounded className={classes.menuIcon} />
        </IconButton>
        <ClickAwayListener
          mouseEvent='onMouseUp'
          touchEvent='onTouchEnd'
          onClickAway={() => editOpen && setEditOpen(false)}
        >
          <Slide in={editOpen} mountOnEnter unmountOnExit direction='left'>
            <TextField
              className={classes.buttonInput}
              value={currentName}
              onChange={(event) => setCurrentName(event.currentTarget.value)}
              // Apply rename and close on enter
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  ;(
                    event.target as HTMLTextAreaElement | HTMLInputElement
                  ).blur()
                  if (editOpen) setEditOpen(false)
                }
              }}
              onBlur={(event) =>
                fileSelectorData.onRename(event, props.photoData)
              }
            />
          </Slide>
        </ClickAwayListener>

        <Menu
          open={menu.open}
          onClose={handleClose}
          anchorEl={menuRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          anchorReference={
            menu.mouseY && menu.mouseX ? 'anchorPosition' : undefined
          }
          anchorPosition={{ top: menu.mouseY, left: menu.mouseX }}
        >
          {fileSelectorData.renamable && (
            <MenuItem
              onClick={() => {
                handleClose()
                setEditOpen((open) => !open)
              }}
            >
              Bearbeiten
            </MenuItem>
          )}
          {fileSelectorData.removable && (
            <MenuItem
              onClick={() => {
                handleClose()
                fileSelectorData.onRemove(props.photoData)
              }}
            >
              Löschen
            </MenuItem>
          )}
          <MenuItem onClick={handleClose}>
            <DownloadButton
              path={fileSelectorData.rootPath + props.photoData.path}
              name={props.photoData.displayName}
            />
          </MenuItem>
          {['image', 'pdf', 'vectorgraphic'].includes(
            props.photoData.filetype
          ) && (
            <MenuItem
              onClick={() => {
                handleClose()
                fileSelectorData.onMaximize(
                  props.photoData as ExpandedFileFileResponse
                )
              }}
            >
              Maximieren
            </MenuItem>
          )}
        </Menu>
      </Box>
    </Box>
  )
}

const fileSelectorUpload = makeStyles((theme: Theme) => ({
  fileSelectorUpload: {
    overflow: 'hidden',
    position: 'relative',
    left: 0,
    top: 0,
    padding: 0
  },
  label: {
    width: '100%',
    height: '100%'
  },
  file: {
    transition: theme.transitions.create(['transform']),
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  },
  upload: {
    width: '100%',
    height: '100%'
  },
  uploadInputHandler: {
    display: 'none'
  }
}))

const FileSelectorUpload = (props: RenderImageProps) => {
  const classes = fileSelectorUpload()

  const fileSelectorData = React.useContext(FileSelectorData)

  return (
    <Button
      variant={fileSelectorData.uploading ? 'contained' : 'outlined'}
      color={fileSelectorData.uploading ? 'primary' : 'default'}
      className={classes.fileSelectorUpload}
      classes={{ label: classes.label }}
      style={
        {
          margin: props.margin,
          height: props.photo.height,
          width: props.photo.width,
          ...(props.direction === 'column' && {
            position: 'absolute',
            left: props.left as number,
            top: props.top as number
          })
        } as React.CSSProperties
      }
      key={props.index}
    >
      <Box
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          event.currentTarget.querySelector('input')?.click()
        }
        className={classes.upload}
      >
        <input
          type='file'
          multiple
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.currentTarget.files)
              fileSelectorData.onUpload(
                event.currentTarget.files as unknown as File[]
              )
          }}
          className={classes.uploadInputHandler}
        />

        <PublishRounded
          className={classes.file}
          style={{ display: fileSelectorData.uploading ? 'none' : '' }}
        />
        <img
          src={LoadingAnimation}
          alt='loading...'
          className={classes.file}
          style={{ display: !fileSelectorData.uploading ? 'none' : '' }}
        />
      </Box>
    </Button>
  )
}

interface DownloadButtonProps {
  path?: Link
  name?: string
}

const DownloadButton = (props: DownloadButtonProps) => {
  return (
    <a
      href={props.path}
      download={props.name} // Download only works on same-origin URLs; Otherwise file is opnened in new tab
      target='_blank'
      style={{ color: 'inherit', textDecoration: 'none' }}
    >
      Herunterladen
    </a>
  )
}
